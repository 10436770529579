import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";

export default function SaveItem(props) {
  const [loading, setLoading] = useState(false);
  const [layer, setlayer] = useState(props.currentItem?.layer ? props.currentItem?.layer : "");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [values, setValues] = React.useState({
    trait: props.currentItem?.trait,
    quantity: props.currentItem?.quantity,
    price: props.currentItem?.price,
    deadline: props.currentItem?.deadline,
    sold: props.currentItem?.sold ? props.currentItem?.sold : 0
  });

  const isFutureDate = (date) => {
    const today = new Date();
    const inputDate = new Date(date);
    return inputDate > today;
  };

  const resetNotification = () => {
    setTimeout(() => {
      setError("");
      setSuccess("");
    }, 4000);
  };

  const saveItem = async () => {
    setError("");
    setSuccess("");

    if (!layer || !values.trait || !values.price) {
      await setError("Layer, Trait, and Price are required fields.");
      await resetNotification();
      return;
    }

    if (values.deadline && values.deadline !== "unlimited" && !isFutureDate(values.deadline)) {
      await setError("Deadline must be a future date.");
      await resetNotification();
      return;
    }

    setLoading(true);

    await axios
      .post(`https://app.themoopians.io/api/addItem`, {
        layer: layer,
        trait: values.trait,
        quantity: values.quantity ? values.quantity : "unlimited",
        price: values.price,
        deadline: values.deadline ? values.deadline : "unlimited",
        sold: values.sold,
      })
      .then(async (res) => {
        await props.setOpen(false);
        await props.getInventorys();
        await setSuccess("Item saved successfully.");
        await resetNotification();
        setLoading(false);
      })
      .catch(async (err) => {
        console.log(err);
        await setError("An error occurred while saving the item.");
        await resetNotification();
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="fixed font-Poppins top-0 left-0 z-50 h-screen w-screen flex justify-center items-center bg-[rgba(0,0,0,0.3)]">
        <div className="p-8 bg-white w-1/3 flex flex-col gap-y-5 text-sm font-medium text-[#141522] rounded-2xl">
          <div className="flex justify-end w-full"><AiOutlineClose size={23} onClick={() => props.setOpen(false)} /></div>

          <div className="space-y-1">
            <label>Layer</label>
            <select name="layer" value={layer} onChange={(e) => setlayer(e.target.value)} className="form-control block w-full rounded-[10px] text-[#27262E] bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none py-4 px-5 border border-[#E2E2E4]">
              <option value="" disabled={true} selected={true}>Select Layer</option>
              {props.layers.map((layer) => (
                <option key={layer.layer} value={layer.layer}>{layer.layer}</option>
              ))}
            </select>
          </div>

          {layer
            ? <div className="space-y-1">
              <label>Trait</label>
              <select name="trait" value={values.trait} onChange={handleChange} className="form-control block w-full rounded-[10px] text-[#27262E] bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none py-4 px-5 border border-[#E2E2E4]">
                <option value="" disabled={true} selected={true}>Select Trait</option>
                {props.layers.map((data) => (
                  data.layer === layer ? data.trait.map((trait) => (
                    <option key={trait} value={trait}>{trait}</option>
                  )) : null
                ))}
              </select>
            </div>
            : null
          }

          <div className="space-y-1">
            <label>Total Quantity</label>
            <input
              type="number"
              name="quantity"
              value={values.quantity}
              onChange={handleChange}
              className="form-control block w-full rounded-[10px] text-[#27262E] bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none py-4 px-5 border border-[#E2E2E4]"
              placeholder="Enter Quantity"
            />
          </div>

          <div className="space-y-1">
            <label>Price</label>
            <input
              type="number"
              name="price"
              value={values.price}
              onChange={handleChange}
              className="form-control block w-full rounded-[10px] text-[#27262E] bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none py-4 px-5 border border-[#E2E2E4]"
              placeholder="Enter Price"
            />
          </div>

          <div className="space-y-1">
            <label>Deadline</label>
            <input
              type="date"
              name="deadline"
              value={values.deadline}
              onChange={handleChange}
              className="form-control block w-full rounded-[10px] text-[#27262E] bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none py-4 px-5 border border-[#E2E2E4]"
            />
          </div>

          <div className="flex justify-end">
            <button className="px-4 py-3 bg-[#E6007C] text-white text-lg font-medium rounded-lg w-1/2" onClick={saveItem}>
              Save
            </button>
          </div>
        </div>
      </div>
      {error ? <div className="w-[250px] py-2 px-5 fixed top-[80px] right-10 bg-red-600 rounded-2xl text-white font-semibold">{error}</div> : null}
      {success ? <div className="w-[250px] py-2 px-5 fixed top-[80px] right-10 bg-green-600 rounded-2xl text-white font-semibold">{success}</div> : null}
      {loading
        ? <div className="fixed  right-0 top-0  transform flex justify-center items-center w-full min-h-screen z-50 bg-[rgba(0,0,0,0.2)]">
          <div className="border-t-transparent border-solid animate-spin  rounded-full border-[#E6007C] border-8 h-52 w-52"></div>
        </div>
        : null}
    </>
  );
}
